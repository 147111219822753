import React, { forwardRef } from 'react';
import { Platform } from 'react-native';
export const SelectDragIndicatorWrapper = (StyledSelectDragIndicatorWrapper) => forwardRef(({ children, ...props }, ref) => {
    if (Platform.OS === 'web') {
        return <></>;
    }
    return (<StyledSelectDragIndicatorWrapper {...props} ref={ref}>
        {children}
      </StyledSelectDragIndicatorWrapper>);
});
