import React, { forwardRef } from 'react';
import { Platform } from 'react-native';
export const SelectContent = (StyledSelectContent) => forwardRef(({ children, ...props }, ref) => {
    if (Platform.OS === 'web') {
        return <>{children}</>;
    }
    return (<StyledSelectContent {...props} ref={ref}>
        {children}
      </StyledSelectContent>);
});
