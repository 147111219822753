import React, { forwardRef } from 'react';
import { Platform } from 'react-native';
export const SelectScrollView = (StyledSelectScrollView) => forwardRef(({ children, ...props }, ref) => {
    if (Platform.OS === 'web') {
        return <>{children}</>;
    }
    return (<StyledSelectScrollView {...props} ref={ref}>
        {children}
      </StyledSelectScrollView>);
});
