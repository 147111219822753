import React, { forwardRef } from 'react';
import { Platform } from 'react-native';
export const SelectSectionList = (StyledSelectSectionList) => forwardRef(({ children, ...props }, ref) => {
    if (Platform.OS === 'web') {
        return (<>
          {props.sections.map((section) => {
                return (<optgroup label={section.title} ref={ref}>
                {section.data.map((item) => (<option value={item}>{item}</option>))}
              </optgroup>);
            })}
        </>);
    }
    return (<StyledSelectSectionList {...props} ref={ref}>
        {children}
      </StyledSelectSectionList>);
});
