import React, { forwardRef } from 'react';
import { Platform } from 'react-native';
export const SelectFlatList = (StyledSelectFlatList) => forwardRef(({ children, ...props }, ref) => {
    if (Platform.OS === 'web') {
        return (<>
          {props.data.map((item) => {
                return props.renderItem({ item });
            })}
        </>);
    }
    return (<StyledSelectFlatList {...props} ref={ref}>
        {children}
      </StyledSelectFlatList>);
});
