import React, { forwardRef } from 'react';
import { SelectContext } from './SelectContext';
import { mergeRefs } from '@gluestack-ui/utils';
import { Keyboard } from 'react-native';
export const SelectTrigger = (StyledSelectTrigger) => forwardRef(({ children, ...props }, ref) => {
    const { isDisabled, hoverRef, setIsOpen, onOpen, isFocused, isFocusVisible, isHovered, isInvalid, isReadOnly, } = React.useContext(SelectContext);
    return (<StyledSelectTrigger onPress={() => {
            if (!isReadOnly) {
                Keyboard.dismiss();
                setIsOpen(true);
                onOpen && onOpen();
            }
        }} states={{
            focus: isFocused,
            focusVisible: isFocusVisible,
            hover: isHovered,
            disabled: isDisabled,
            invalid: isInvalid,
        }} dataSet={{
            focus: isFocused ? 'true' : 'false',
            focusVisible: isFocusVisible ? 'true' : 'false',
            hover: isHovered ? 'true' : 'false',
            disabled: isDisabled ? 'true' : 'false',
            invalid: isInvalid ? 'true' : 'false',
        }} disabled={isDisabled} role="button" ref={mergeRefs([ref, hoverRef])} tabIndex={-1} {...props}>
        {children}
      </StyledSelectTrigger>);
});
