import React, { forwardRef } from 'react';
import { Platform } from 'react-native';
export const SelectDragIndicator = (StyledSelectDragIndicator) => forwardRef(({ children, ...props }, ref) => {
    if (Platform.OS === 'web') {
        return <></>;
    }
    return (<StyledSelectDragIndicator {...props} ref={ref}>
        {children}
      </StyledSelectDragIndicator>);
});
