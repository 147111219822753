import React, { forwardRef } from 'react';
import { Platform } from 'react-native';
export const SelectBackdrop = (StyledSelectBackdrop) => forwardRef(({ children, ...props }, ref) => {
    if (Platform.OS === 'web') {
        return <></>;
    }
    return (<StyledSelectBackdrop {...props} ref={ref}>
        {children}
      </StyledSelectBackdrop>);
});
