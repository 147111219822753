import React, { forwardRef } from 'react';
import { Platform } from 'react-native';
export const SelectVirtualizedList = (StyledSelectVirtualizedList) => forwardRef(({ children, ...props }, ref) => {
    if (Platform.OS === 'web') {
        return (<>
          {props.data.map((_, index) => {
                const item = props.getItem(props.data, index);
                return props.renderItem({ item });
            })}
        </>);
    }
    return (<StyledSelectVirtualizedList {...props} ref={ref}>
        {children}
      </StyledSelectVirtualizedList>);
});
